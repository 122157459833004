/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Sono:wght@100;200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

.sono {
    font-family: 'Sono', sans-serif;
}

.raleway {
    font-family: 'Raleway', sans-serif;
}

.profile-pic {
    border-radius: 100%;
}

.navbarstyle {
    z-index: 1000;
}

.silversun {
    max-width: 300;
}

/* .text-red {
    color: #fbcfea
}

.text-yellow {
    color: #feffbe
}

.text-green {
    color: #cbffe6
}

.text-blue {
    color: #afe9ff
}

.text-purple {
    color: #bfb9fe
} */


.vh-fill {
    top: 56px;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    width: auto;
    height: auto;
}

code {color: black}
table * code {color: white}

.bracketstacks {
    background-image: url("/public/images/brackets.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #dddd;
    background-blend-mode: overlay;
}

.tacocat {
    background-image: url("/public/images/tacocat.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #eeee;
    background-blend-mode: overlay;
}

.img-project {
    width: 100%;
    height: 300px;
    object-fit: contain;
}